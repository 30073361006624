import {cloneDeep} from 'lodash-es'
import {ref} from 'vue'

// Clone and wrap initialStore values with ref()
const useStoreState = (initialState) =>
  Object.entries(cloneDeep(initialState)).reduce(
    (acc, [key, value]) => ({
      ...acc,
      [key]: ref(value),
    }),
    {},
  )

export default useStoreState
