import {ACTIONS} from '@/constants'

const projectsRoutes = [
  {
    path: '/projects',
    name: 'Projects',
    component: () => import('@/views/projects/ProjectsView.vue'),
    meta: {
      title: 'Projects',
      requiresPermissions: {
        allOf: [ACTIONS.VIEW_PROJECT_LIST],
      },
    },
    children: [
      {
        path: 'new',
        name: 'ProjectsNew',
        component: () => import('@/views/projects/ProjectsNewView.vue'),
        meta: {
          isOverlay: true,
          requiresPermissions: {
            allOf: [ACTIONS.MODIFY_JOBS],
          },
        },
      },
      {
        path: ':jobId/edit',
        name: 'ProjectsEdit',
        component: () => import('@/views/projects/ProjectsEditView.vue'),
        meta: {
          isOverlay: true,
          requiresPermissions: {
            allOf: [ACTIONS.MODIFY_JOBS],
          },
        },
      },
      {
        path: ':jobId/external-costs',
        name: 'ProjectsExternalCosts',
        component: () => import('@/views/projects/ProjectsExternalCostsView.vue'),
        meta: {
          isOverlay: true,
        },
        children: [
          {
            path: 'new',
            name: 'ProjectsExternalCostsNew',
            component: () => import('@/views/projects/ProjectsExternalCostsNewView.vue'),
          },
          {
            path: ':externalCostId',
            name: 'ProjectsExternalCostsEdit',
            component: () => import('@/views/projects/ProjectsExternalCostsEditView.vue'),
          },
        ],
      },
      {
        path: ':jobId/scheduled-tasks',
        name: 'ProjectsScheduledTasks',
        component: () => import('@/views/projects/ProjectsScheduledTasksView.vue'),
        meta: {
          isOverlay: true,
        },
      },
      {
        path: ':jobId/tracked-tasks',
        name: 'ProjectsTrackedTasks',
        component: () => import('@/views/projects/ProjectsTrackedTasksView.vue'),
        meta: {
          isOverlay: true,
        },
      },
      {
        path: ':jobId/tracked-types',
        name: 'ProjectsTrackedTypes',
        component: () => import('@/views/projects/ProjectsTrackedTypesView.vue'),
        meta: {
          isOverlay: true,
        },
      },
      {
        path: ':jobId/invoices',
        name: 'ProjectsInvoices',
        component: () => import('@/views/projects/ProjectsInvoicesView.vue'),
        meta: {
          isOverlay: true,
        },
      },
    ],
  },
]

export default projectsRoutes
