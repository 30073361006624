const timeTrackingRoutes = [
  {
    path: '/time-tracking',
    component: () => import('@/views/time-tracking/TimeTrackingView.vue'),
    meta: {
      title: 'Time Tracking',
    },
    children: [
      {
        path: '',
        name: 'TimeTracking',
        component: () => import('@/views/time-tracking/DraftsView/index.vue'),
      },
      {
        path: 'recorder',
        name: 'TimeTrackingRecorder',
        component: () => import('@/views/time-tracking/RecorderView.vue'),
        meta: {
          title: 'Start/Stop Recorder',
        },
      },
      {
        path: 'weekly/:userId?',
        name: 'TimeTrackingWeekly',
        component: () => import('@/views/time-tracking/WeeklyView.vue'),
        meta: {
          title: 'Weekly',
          preventScrollOnParamsChange: true,
        },
      },
      {
        path: 'archive',
        name: 'TimeTrackingArchive',
        component: () => import('@/views/time-tracking/ArchiveView.vue'),
        meta: {
          title: 'Archive',
        },
        children: [
          {
            path: 'new',
            name: 'TimeTrackingArchiveNew',
            component: () => import('@/views/time-tracking/ArchiveNewView.vue'),
            meta: {
              isOverlay: true,
            },
          },
          {
            path: ':taskId/edit',
            name: 'TimeTrackingArchiveEdit',
            component: () => import('@/views/time-tracking/ArchiveEditView.vue'),
            meta: {
              isOverlay: true,
            },
          },
        ],
      },
    ],
  },
]

export default timeTrackingRoutes
