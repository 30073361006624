<script setup>
import {LoopThemeProvider} from '@loop/component-library'
import {TouchBackend} from 'react-dnd-touch-backend'
import {onMounted, ref} from 'vue'
import {RouterView, useRouter} from 'vue-router'
import {DndProvider} from 'vue3-dnd'

import FullPageLoader from '@/components/global/FullPageLoader.vue'
import UpdatePrompt from '@/components/global/UpdatePrompt.vue'
import {DEFAULT_ROUTE_AFTER_LOGIN, LOCAL_STORAGE_ACCESS_TOKEN_KEY} from '@/constants'
import {provideIntl, createIntl} from '@/i18n'
import useAuthenticationCallbackStore from '@/stores/authentication/callback'
import useUsersMeStore from '@/stores/users/me'
import routerPushSafe from '@/utils/router-push-safe'
import showErrorMessage from '@/utils/show-error-message'

import OfflineMessage from './components/global/OfflineMessage.vue'
import useMeasureViewport from './composables/use-measure-viewport'
import isAuthorizationError from './utils/is-authorization-error'

const router = useRouter()
const usersMeStore = useUsersMeStore()
const authenticationCallbackStore = useAuthenticationCallbackStore()
const isLoading = ref(false)

useMeasureViewport()
provideIntl(ref(createIntl()))

const handleAuthToken = () => {
  const query = new URLSearchParams(window.location.search)
  const accessToken = query.get('access_token')

  if (accessToken) {
    localStorage.setItem(LOCAL_STORAGE_ACCESS_TOKEN_KEY, accessToken)
    query.delete('access_token')
    router.replace({path: window.location.pathname, query: query.toString()})
  }
}

const handleAzureCallback = async () => {
  // Abstain from using route.query as router is not initialized yet at this point
  const query = new URLSearchParams(window.location.search)
  const code = query.get('code')
  const sessionState = query.get('session_state')

  // Trigger azure callback if code an session_state query params are present
  if (code && sessionState) {
    try {
      await authenticationCallbackStore.load({
        code,
        sessionState,
      })

      routerPushSafe(localStorage.getItem('loginFrom'), DEFAULT_ROUTE_AFTER_LOGIN)
    } catch (error) {
      router.push({name: 'Login'})
      showErrorMessage('Error authenticating with server', error)
    }
  }
}

const handleUserDataLoading = async () => {
  // Load user data initially
  try {
    await usersMeStore.load()
  } catch (error) {
    if (isAuthorizationError(error)) {
      if (router.currentRoute.value.name !== 'Login') {
        showErrorMessage('You are not logged in.', error)
      }
    } else {
      showErrorMessage('Error loading your user data.', error)
    }
  }
}

onMounted(async () => {
  isLoading.value = true

  handleAuthToken()
  await handleAzureCallback()
  await handleUserDataLoading()

  isLoading.value = false
})
</script>

<template>
  <DndProvider
    :backend="TouchBackend"
    :options="{enableMouseEvents: true, enableKeyboardEvents: true}"
  >
    <LoopThemeProvider>
      <FullPageLoader v-if="isLoading" />
      <UpdatePrompt />
      <OfflineMessage />
      <RouterView />
    </LoopThemeProvider>
  </DndProvider>
</template>
