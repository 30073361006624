<script setup>
import {LoopMessage} from '@loop/component-library'
import {useOnline} from '@vueuse/core'
import {ref, watch} from 'vue'

const isOnline = useOnline()
const isMessageVisible = ref(false)
let messageHandler = null

watch(
  isOnline,
  () => {
    if (!isOnline.value && !isMessageVisible.value) {
      isMessageVisible.value = true

      messageHandler = LoopMessage.error({
        showClose: true,
        message: "You're currently offline.",
        duration: 0,
        onClose: () => (isMessageVisible.value = false),
      })
    } else if (isOnline.value && isMessageVisible.value) {
      isMessageVisible.value = false
      messageHandler?.close?.()
      messageHandler = null
    }
  },
  {immediate: true},
)
</script>

<template>
  <slot />
</template>
