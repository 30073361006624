import {isPlainObject} from 'lodash-es'

import snakeToCamel from './snake-to-camel'

const camelCaseKeys = (input) => {
  if (isPlainObject(input)) {
    return Object.fromEntries(
      Object.entries(input).map(([key, value]) => {
        if (isPlainObject(value)) {
          return [snakeToCamel(key), camelCaseKeys(value)]
        } else if (Array.isArray(value)) {
          return [snakeToCamel(key), value.map(camelCaseKeys)]
        }
        return [snakeToCamel(key), value]
      }),
    )
  } else {
    return input
  }
}

export default camelCaseKeys
