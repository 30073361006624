import {defineStore} from 'pinia'

import useStoreState from '@/composables/use-store-state'
import getCallbackAzure from '@/services/intranet-api/callback/azure'
import getCsrfCookie from '@/services/intranet-api/sanctum/csrf-cookie'

const initialState = {
  data: null,
  isLoading: false,
  error: null,
}

const useAuthenticationCallbackStore = defineStore('authenticationCallback', () => {
  const state = useStoreState(initialState)

  const actions = {
    load: async ({code, sessionState}) => {
      try {
        state.error.value = null
        state.data.value = null
        state.isLoading.value = true

        await getCsrfCookie()
        await getCallbackAzure({
          redirect: import.meta.env.VITE_LOGIN_REDIRECT_PARAM || undefined,
          code,
          sessionState,
        })

        state.isLoading.value = false
      } catch (error) {
        state.error.value = error
        state.isLoading.value = false
        throw error
      }
    },
  }

  return {
    ...state,
    ...actions,
  }
})

export default useAuthenticationCallbackStore
