import {RouterView} from 'vue-router'

import {ACTIONS} from '@/constants'

const handbookRoutes = [
  {
    path: '/handbook',
    name: 'Handbook',
    meta: {
      title: 'Handbook',
      requiresPermissions: {
        allOf: [ACTIONS.VIEW_ANY_HANDBOOKS],
      },
    },
    component: () => import('@/views/handbook/HandbookView.vue'),
    children: [
      {
        path: 'categories/:handbookCategoryId(\\d+)',
        name: 'HandbookCategory',
        component: RouterView,
      },
      {
        path: 'articles',
        component: RouterView,
        children: [
          {
            path: 'new',
            name: 'HandbookArticleNew',
            component: () => import('@/views/handbook/HandbookArticleNewView.vue'),
            meta: {
              requiresPermissions: {
                allOf: [ACTIONS.MODIFY_HANDBOOKS],
              },
            },
          },
          {
            path: ':handbookArticleId(\\d+)',
            name: 'HandbookArticle',
            component: () => import('@/views/handbook/HandbookArticleView.vue'),
          },
          {
            path: ':handbookArticleId(\\d+)/edit',
            name: 'HandbookArticleEdit',
            component: () => import('@/views/handbook/HandbookArticleEditView.vue'),
            meta: {
              requiresPermissions: {
                allOf: [ACTIONS.MODIFY_HANDBOOKS],
              },
            },
          },
        ],
      },
    ],
  },
]

export default handbookRoutes
