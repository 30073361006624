<script setup>
import {LOOP_BUTTON_SIZES, LOOP_BUTTON_TYPES, LoopButton} from '@loop/component-library'
import {onMounted} from 'vue'
import {useRoute} from 'vue-router'

import {DEFAULT_ROUTE_AFTER_LOGIN} from '@/constants'
import useUsersMeStore from '@/stores/users/me'
import routerPushSafe from '@/utils/router-push-safe'

const route = useRoute()
const usersMeStore = useUsersMeStore()

const onLoginClick = () => {
  if (route.query.from) {
    localStorage.setItem('loginFrom', route.query.from)
  } else {
    localStorage.removeItem('loginFrom')
  }
  window.location.href = `${import.meta.env.VITE_INTRANET_API_BASE}/login/azure?redirect=${import.meta.env.VITE_LOGIN_REDIRECT_PARAM}`
}

onMounted(async () => {
  const isLoggedIn = await usersMeStore.isLoggedIn

  if (isLoggedIn) {
    routerPushSafe(route.query?.from, DEFAULT_ROUTE_AFTER_LOGIN)
  }
})
</script>

<template>
  <div :class="$style.wrapper">
    <div :class="$style.left">
      <h1 :class="$style.title">
        At your service.<br />
        LOOP.Intranet
      </h1>
    </div>
    <div :class="$style.right">
      <div :class="$style.rightInner">
        <div :class="$style.logo">
          <svg width="32" height="32" viewBox="0 0 32 32" xmlns="http://www.w3.org/2000/svg">
            <path
              d="
                m29.192 6.965-1.71 1.708a13.523 13.523 0 0 1 2.156 7.325c0 7.523-6.119 13.644-13.639
                13.644S2.361 23.52 2.361 15.998c0-7.519 6.118-13.637 13.638-13.637 2.708 0 5.231.805
                7.353 2.17l1.703-1.704A15.884 15.884 0 0 0 15.999 0C7.179 0 0 7.176 0 15.998 0
                24.823 7.18 32 15.999 32 24.819 32 32 24.823 32
                15.998c0-3.348-1.043-6.46-2.808-9.033z
              "
              fill="#1F1F1F"
              fill-rule="evenodd"
            />
          </svg>

          <span>LOOP.Intranet</span>
        </div>

        <LoopButton
          :class="$style.button"
          :size="LOOP_BUTTON_SIZES.LARGE"
          :type="LOOP_BUTTON_TYPES.PRIMARY"
          @click="onLoginClick"
        >
          Authenticate with SSO
        </LoopButton>
      </div>
    </div>
  </div>
</template>

<style lang="scss" module>
.wrapper {
  display: flex;
  width: 100vw;
  min-height: 100vh;
}

.left {
  background: #f2f2f2;
  width: calc(100vw - 600px);
  position: relative;
}

.title {
  font-size: 5vw;
  position: absolute;
  left: 100px;
  bottom: 100px;
  font-weight: normal;
}

.right {
  width: 600px;
}

.rightInner {
  margin: 0 auto;
  width: calc(100% - 200px);
  padding-top: 100px;
  position: relative;
  height: 100%;
}

.logo {
  svg {
    height: 32px;
    margin-right: 20px;
  }

  span {
    font-weight: bold;
    font-size: 14px;
    line-height: 32px;
    display: inline-block;
    transform: translateY(-11px);
  }
}

.button {
  width: 100%;
  position: absolute;
  left: 0;
  bottom: 100px;
}
</style>
