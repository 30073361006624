import {useWindowSize, useElementSize} from '@vueuse/core'
import {watch} from 'vue'
import {useRoute} from 'vue-router'

const useMeasureViewport = () => {
  const {width: windowWidth, height: windowHeight} = useWindowSize()
  const {width: documentWidth, height: documentHeight} = useElementSize(document.documentElement)
  const route = useRoute()

  watch(
    // At the time of writing, clientHeight and clientWidth cannot be observed.
    // So we are listening on a bunch of parameters to update the viewport units.
    [windowWidth, windowHeight, documentWidth, documentHeight, route],
    () => {
      document.documentElement.style.setProperty(
        '--vw',
        `${document.documentElement.clientWidth / 100}px`,
      )
      document.documentElement.style.setProperty(
        '--vh',
        `${document.documentElement.clientHeight / 100}px`,
      )
    },
    {immediate: true},
  )
}

export default useMeasureViewport
