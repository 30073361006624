import {LoopLink, LoopMessage, LoopMessageBox} from '@loop/component-library'
import {noop} from 'lodash-es'
import {h} from 'vue'

const getDetails = async (error) => {
  let message = error?.response?.data?.message ?? error?.message

  if (error?.response?.data instanceof Blob && error?.response?.data?.type === 'application/json') {
    const text = await error?.response?.data?.text()
    const json = JSON.parse(text)
    message = json.message
  }

  return message
}

const showErrorMessage = async (message, error) => {
  const details = await getDetails(error)
  const onClick = () => {
    LoopMessageBox.alert(details, 'Error details').catch(noop)
  }
  const linkStyle = {whiteSpace: 'nowrap', color: 'var(--el-message-close-icon-color)'}

  LoopMessage({
    type: 'error',
    message: () => [
      h('p', {class: 'el-message__content'}, message),

      ...(details
        ? [h(LoopLink, {onClick, style: linkStyle, permanentUnderline: true}, () => 'See details')]
        : []),
    ],
  })
}

export default showErrorMessage
