import {ACTIONS} from '@/constants'

const briefingsRoutes = [
  {
    path: '/briefings',
    meta: {
      title: 'Briefings',
      requiresPermissions: {
        allOf: [ACTIONS.VIEW_ANY_BRIEFINGS],
      },
    },
    children: [
      {
        path: '',
        name: 'Briefings',
        component: () => import('@/views/briefings/BriefingsView.vue'),
        children: [
          {
            path: 'new',
            name: 'BriefingsNew',
            component: () => import('@/views/briefings/BriefingsNewView.vue'),
            meta: {
              isOverlay: true,
              requiresPermissions: {
                allOf: [ACTIONS.MODIFY_BRIEFINGS],
              },
            },
          },
        ],
      },
      {
        path: '/briefings/:briefingId/edit',
        name: 'BriefingsEdit',
        component: () => import('@/views/briefings/BriefingsEditView.vue'),
        meta: {
          requiresPermissions: {
            allOf: [ACTIONS.MODIFY_BRIEFINGS],
          },
        },
      },
      {
        path: '/briefings/:briefingId',
        name: 'BriefingsShow',
        component: () => import('@/views/briefings/BriefingsShowView.vue'),
      },
    ],
  },
]

export default briefingsRoutes
