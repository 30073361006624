import {dayjs} from '@loop/component-library'
import * as Sentry from '@sentry/vue'
import {createPinia} from 'pinia'
import {createORM} from 'pinia-orm'
import {createApp} from 'vue'
import VueSafeTeleport from 'vue-safe-teleport'

import App from './App.vue'
import router from './router'

import '@loop/component-library/dist/style.css'
import '@/assets/styles/main.scss'

// Configure dayjs (used by element-plus) to start the week on Monday
dayjs.Ls.en.weekStart = 1

const app = createApp(App)

Sentry.init({
  app,
  enabled: import.meta.env.PROD,
  environment: import.meta.env.MODE,
  dsn: 'https://e3acc82fbb8a38e7d7b63687648b7bf4@sentry.agentur-loop.dev/2',
  integrations: [
    Sentry.browserTracingIntegration(),
    Sentry.replayIntegration({workerUrl: '/sentry-replay-worker.min.js'}),
  ],
  // Performance Monitoring
  tracesSampleRate: 1.0, //  Capture 100% of the transactions
  // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
  tracePropagationTargets: [],
  // Session Replay
  // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysSessionSampleRate: 0.1,
  // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
  replaysOnErrorSampleRate: 1.0,
})

const pinia = createPinia()

pinia.use(createORM())

app.use(pinia)
app.use(router)
app.use(VueSafeTeleport)

// Enable performance metrics in development
if (import.meta.env.DEV) {
  app.config.performance = true
}

app.mount('#app')
