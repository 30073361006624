import {isPlainObject} from 'lodash-es'

import camelToSnake from './camel-to-snake'

const IRREGULARS = {
  // Provide irregular keys here like: "iso6391: 'iso_639_1',"
}

const snakeCaseKeys = (input) => {
  if (isPlainObject(input)) {
    return Object.fromEntries(
      Object.entries(input).map(([key, value]) => {
        // Handle irregulars and dots in keys
        const casedKey = IRREGULARS[key] || camelToSnake(key)

        if (isPlainObject(value)) {
          return [casedKey, snakeCaseKeys(value)]
        } else if (Array.isArray(value)) {
          return [casedKey, value.map(snakeCaseKeys)]
        }
        return [casedKey, value]
      }),
    )
  } else {
    return input
  }
}

export default snakeCaseKeys
