import {defineStore} from 'pinia'

import useStoreState from '@/composables/use-store-state'
import getLogout from '@/services/intranet-api/logout'

const initialState = {
  data: null,
  isLoading: false,
  error: null,
}

const useAuthenticationLogoutStore = defineStore('authenticationLogout', () => {
  const state = useStoreState(initialState)

  const actions = {
    logout: async () => {
      try {
        state.error.value = null
        state.data.value = null
        state.isLoading.value = true

        await getLogout()

        state.isLoading.value = false
      } catch (error) {
        state.error.value = error
        state.isLoading.value = false
        throw error
      }
    },
  }

  return {
    ...state,
    ...actions,
  }
})

export default useAuthenticationLogoutStore
