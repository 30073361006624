<script setup>
import {LoopDialog, LoopButton} from '@loop/component-library'
import {useEventListener, useInterval} from '@vueuse/core'
// eslint-disable-next-line import/no-unresolved
import {useRegisterSW} from 'virtual:pwa-register/vue'
import {computed, onUnmounted, ref, watch} from 'vue'

const intervalMS = 10 * 60 * 1000 // 10 minutes
let intervalId = null
const checkForUpdate = async (swUrl, registration) => {
  if (!(!registration.installing && navigator)) {
    return
  }

  if ('connection' in navigator && !navigator.onLine) {
    return
  }

  try {
    const response = await fetch(swUrl, {
      cache: 'no-store',
      headers: {
        cache: 'no-store',
        'cache-control': 'no-cache',
      },
    })

    if (response?.status === 200) {
      await registration.update()
    }
  } catch (error) {
    // Noop
  }
}
const {needRefresh, updateServiceWorker} = useRegisterSW({
  onRegisteredSW(swUrl, registration) {
    if (registration) {
      intervalId = setInterval(() => checkForUpdate(swUrl, registration), intervalMS)
    }
  },
  onNeedRefresh() {
    // Request client count from service worker
    clientCount.value = null
    navigator.serviceWorker?.controller?.postMessage?.({type: 'COUNT_CLIENTS'})
  },
})

onUnmounted(() => {
  clearInterval(intervalId)
})

const clientCount = ref(null)
const secondsSinceLoad = useInterval(1000)
const needApproval = computed(() => clientCount.value > 1 || secondsSinceLoad.value > 10)

// Listen for client count from service worker
useEventListener(navigator.serviceWorker, 'message', (event) => {
  if (event.data?.type === 'COUNTED_CLIENTS') {
    clientCount.value = event.data.clientCount
  }
})

// Force update service worker when only one client is accessing it within first 10 seconds after page load
watch([needRefresh, clientCount], ([needRefresh, clientCount]) => {
  if (needRefresh && clientCount && clientCount <= 1 && secondsSinceLoad.value <= 10) {
    updateServiceWorker()
  }
})

const onUpdate = () => {
  updateServiceWorker()
  needRefresh.value = false

  // Reload the page so service worker can take control
  if (navigator.serviceWorker && !navigator.serviceWorker.controller) {
    window.location.reload()
  }
}

const onCancel = () => {
  needRefresh.value = false
}
</script>

<template>
  <LoopDialog
    :model-value="needRefresh && needApproval"
    :width="420"
    @update:model-value="(event) => (needRefresh = event)"
  >
    <template #header>
      <h4 :class="$style.title">Update available</h4>
    </template>

    <p :class="$style.text">
      LOOP.Intranet has a new version available. Do you want to update now? All open LOOP.Intranet
      tabs will reload, so save your work first.
    </p>

    <template #footer>
      <div :class="$style.buttonContainer">
        <LoopButton type="primary" @click="onUpdate"> Update </LoopButton>
        <LoopButton @click="onCancel">Cancel</LoopButton>
      </div>
    </template>
  </LoopDialog>
</template>

<style lang="scss" module>
.title {
  text-transform: uppercase;
  line-height: 24px;
}

.text {
  line-height: 24px;
}

.buttonContainer {
  margin-top: 14px;
}
</style>
