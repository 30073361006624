import router from '@/router'

const routerPushSafe = (to, fallback) => {
  // Hard redirect to nova panel
  if (to === '/nova') {
    window.location.href = '/nova'
    return
  }

  if (to && router.resolve(to)?.name && router.resolve(to)?.name !== 'NotFound') {
    router.push(to)
  } else {
    router.push(fallback)
  }
}

export default routerPushSafe
