import {createIntl as createIntlOg} from '@formatjs/intl'
import {provideIntl, useIntl} from 'vue-intl'

import de from './translations/de.json'
import en from './translations/en.json'

const INTL_KEY = 'intl'
const DEFAULT_LOCALE = 'en'

const messagesCache = {de, en}

const createIntl = (options = {}) => {
  const messages = messagesCache[options.locale] || messagesCache[DEFAULT_LOCALE]

  return createIntlOg({
    locale: DEFAULT_LOCALE,
    defaultLocale: DEFAULT_LOCALE,
    messages,
    ...options,
  })
}

export {INTL_KEY, provideIntl, useIntl, createIntl}
